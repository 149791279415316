import React from 'react'
import Layout from '../components/layout'
import Heading from '../components/page-heading'
import Paragraph from '../components/elements/paragraph'
import Button from '../components/button'

export default () => (
  <Layout>
    <Heading>Thank you!</Heading>
    <Paragraph>We usually respond in less than 24 hours.</Paragraph>
    <Button to="/">Go back to sandiegocpa.com</Button>
  </Layout>
)
